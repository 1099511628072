import React from "react"

import PageSubHeader from "components/PageSubHeader/PageSubHeader"

const PrivacyPolicyContentTab5 = () => {
  return (
    <>
      <PageSubHeader title="§ 5 Zarządzanie bezpieczeństwem - hasło" />
      <ol>
        <li>
          MEMTECH Sp. z o.o. Sp. k. zapewnia Klientom bezpieczne i szyfrowane
          połączenie podczas przesyłania danych osobowych oraz podczas logowania
          się do Konta Klienta w Serwisie. MEMTECH Sp. z o.o. Sp. k. stosuje
          certyfikat SSL wystawiony przez jedną z czołowych światowych firm w
          zakresie bezpieczeństwa i szyfrowania przesyłanych danych przez sieć
          Internet.
        </li>
        <li>
          W przypadku, gdy Klient posiadający konto w Sklepie Internetowym
          utracił w jakikolwiek sposób hasło dostępu, Sklep Internetowy
          umożliwia wygenerowanie nowego hasła. MEMTECH Sp. z o.o. Sp. k. nie
          wysyła przypomnienia hasła. Hasło jest przechowywane w formie
          zaszyfrowanej, w sposób uniemożliwiający jego odczytanie. Celem
          wygenerowania nowego hasła należy podać adres e-mail w formularzu
          dostępnym pod linkiem „Nie pamiętasz hasła”, podanym przy formularzu
          logowania do konta w Sklepie Powered by TCPDF (www.tcpdf.org)
          Internetowym. Klient na adres poczty elektronicznej podany podczas
          rejestracji lub zapisany w ostatniej zmianie profilu konta otrzyma
          wiadomość elektroniczną zawierającą przekierowanie do dedykowanego
          formularza udostępnionego na Stronie Internetowej Sklepu, gdzie Klient
          będzie miał możliwość ustalenia nowego hasła.
        </li>
        <li>
          MEMTECH Sp. z o.o. Sp. k. nigdy nie wysyła żadnej korespondencji, w
          tym korespondencji elektronicznej z prośbą o podanie danych do
          logowania, a w szczególności hasła dostępowego do konta Klienta.
        </li>
      </ol>
    </>
  )
}

export default PrivacyPolicyContentTab5
