import React from "react"

import PageSubHeader from "components/PageSubHeader/PageSubHeader"

const PrivacyPolicyContentTab3 = () => {
  return (
    <>
      <PageSubHeader title="§ 3 Mechanizm cookies, adres IP" />

      <ol>
        <li>
          Sklep Internetowy używa niewielkich plików, zwanych cookies.
          Zapisywane są one przez MEMTECH Sp. z o.o. Sp. k. na urządzeniu
          końcowym osoby odwiedzającej Sklep Internetowy, jeżeli przeglądarka
          internetowa na to pozwala. Plik cookie zwykle zawiera nazwę domeny, z
          której pochodzi, swój „czas wygaśnięcia" oraz indywidualną, losowo
          wybraną liczbę identyfikującą ten plik. Informacje zbierane za pomocą
          plików tego typu pomagają dostosowywać oferowane przez MEMTECH Sp. z
          o.o. Sp. k. produkty do indywidualnych preferencji i rzeczywistych
          potrzeb osób odwiedzających Sklep Internetowy. Dają też możliwość
          opracowywania ogólnych statystyk odwiedzin prezentowanych produktów w
          Sklepie Internetowym.
        </li>
        <li>MEMTECH Sp. z o.o. Sp. k. wykorzystuje dwa typy plików cookies:</li>
        <ol>
          <li>
            Cookies sesyjne: po zakończeniu sesji danej przeglądarki lub
            wyłączeniu komputera zapisane informacje są usuwane z pamięci
            urządzenia. Mechanizm cookies sesyjnych nie pozwala na pobieranie
            jakichkolwiek danych osobowych ani żadnych informacji poufnych z
            komputerów Klientów.
          </li>
          <li>
            Cookies trwałe: są przechowywane w pamięci urządzenia końcowego
            Klienta i pozostają tam do momentu ich skasowania lub wygaśnięcia.
            Mechanizm cookies trwałych nie pozwala na pobieranie jakichkolwiek
            danych osobowych ani żadnych informacji poufnych z komputera
            Klientów.
          </li>
        </ol>
        <li>MEMTECH Sp. z o.o. Sp. k. wykorzystuje cookies własne w celu:</li>
        <ol>
          <li>
            uwierzytelniania Klienta w Sklepie Internetowym i zapewnienia sesji
            Klienta w Sklepie Internetowym (po zalogowaniu), dzięki której
            Klient nie musi na każdej podstronie Sklepu Internetowego ponownie
            wpisywać loginu i hasła;
          </li>
          <li>
            analiz i badań oraz audytu oglądalności, a w szczególności do
            tworzenia anonimowych statystyk, które pomagają zrozumieć, w jaki
            sposób Klienci korzystają ze Strony Internetowej Sklepu, co
            umożliwia ulepszanie jej struktury i zawartości.
          </li>
        </ol>
        <li>
          MEMTECH Sp. z o.o. Sp. k. wykorzystuje cookies zewnętrzne w celu:
        </li>
        <ol>
          <li>
            zbierania ogólnych i anonimowych danych statycznych za pośrednictwem
            narzędzi analitycznych Google Analytics (administrator cookies
            zewnętrznego: Google Inc z siedzibą w USA);
          </li>
          <li>
            prezentacji Certyfikatu Rzetelny Regulamin za pośrednictwem serwisu
            internetowego rzetelnyregulamin.pl (administrator cookies
            zewnętrznego: Rzetelna Grupa sp. z o.o. z siedzibą w Warszawie).
          </li>
        </ol>
        <li>
          Mechanizm cookies jest bezpieczny dla komputerów Klientów Sklepu
          Internetowego. W szczególności tą drogą nie jest możliwe przedostanie
          się do komputerów Klientów wirusów lub innego niechcianego
          oprogramowania lub oprogramowania złośliwego. Niemniej w swoich
          przeglądarkach Klienci mają możliwość ograniczenia lub wyłączenia
          dostępu plików cookies do komputerów. W przypadku skorzystania z tej
          opcji korzystanie ze Sklepu Internetowego będzie możliwe, poza
          funkcjami, które ze swojej natury wymagają plików cookies.
        </li>
        <li>
          Poniżej przedstawiamy jak można zmienić ustawienia popularnych
          przeglądarek internetowych w zakresie stosowania plików cookies:
        </li>
        <ol>
          <li>
            przeglądarka{" "}
            <a
              href="https://support.google.com/chrome/answer/95647?hl=pl"
              target="_blank"
              rel="noreferrer"
            >
              Chrome i Chrome Mobile
            </a>
            ;
          </li>
          <li>
            przeglądarka{" "}
            <a
              href="https://www.facebook.com/policies/cookies"
              target="_blank"
              rel="noreferrer"
            >
              Facebook in-app Browser
            </a>
            ;
          </li>
          <li>
            przeglądarka{" "}
            <a
              href="https://support.microsoft.com/pl-pl/topic/jak-usunąć-pliki-cookie-w-programie-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc"
              target="_blank"
              rel="noreferrer"
            >
              Internet Explorer
            </a>
            ;
          </li>
          <li>
            przeglądarka{" "}
            <a
              href="https://support.microsoft.com/pl-pl/windows/program-microsoft-edge-i-przeglądanie-danych-a-prywatność-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd"
              target="_blank"
              rel="noreferrer"
            >
              Microsoft EDGE
            </a>
            ;
          </li>
          <li>
            przeglądarka{" "}
            <a
              href="https://support.mozilla.org/pl/kb/ciasteczka"
              target="_blank"
              rel="noreferrer"
            >
              Mozilla Firefox
            </a>
            ;
          </li>
          <li>
            przeglądarka{" "}
            <a
              href="https://help.opera.com/pl/latest/web-preferences/#cookies"
              target="_blank"
              rel="noreferrer"
            >
              Opera
            </a>
            .
          </li>
          <li>
            przeglądarka{" "}
            <a
              href="https://support.apple.com/pl-pl/HT201265"
              target="_blank"
              rel="noreferrer"
            >
              Safari i Safari Mobile
            </a>
            ;
          </li>
          <li>
            przeglądarka{" "}
            <a
              href="https://www.samsung.com/uk/support/mobile-devices/what-are-cookies-and-how-do-i-enable-or-disable-them-on-my-samsung-galaxy-device/"
              target="_blank"
              rel="noreferrer"
            >
              Samsung Browser
            </a>
            .
          </li>
        </ol>
        <li>
          MEMTECH Sp. z o.o. Sp. k. może gromadzić adresy IP Klientów. Adres IP
          to numer przydzielany komputerowi osoby odwiedzającej Sklep
          Internetowy przez dostawcę usług internetowych. Numer IP umożliwia
          dostęp do Internetu. W większości przypadków jest przypisywany
          komputerowi dynamicznie, tj. zmienia się przy każdym połączeniu z
          Internetem. Adres IP jest wykorzystywany przez MEMTECH Sp. z o.o. Sp.
          k. przy diagnozowaniu problemów technicznych z serwerem, tworzeniu
          analiz statystycznych (np. określeniu, z jakich regionów notujemy
          najwięcej odwiedzin), jako informacja przydatna przy administrowaniu i
          udoskonalaniu Sklepu Internetowego, a także w celach bezpieczeństwa
          oraz ewentualnej identyfikacji obciążających serwer, niepożądanych
          automatycznych programów do przeglądania treści Sklepu Internetowego.
        </li>
        <li>
          Sklep Internetowy zawiera linki i odnośniki do innych stron
          internetowych. MEMTECH Sp. z o.o. Sp. k. nie ponosi odpowiedzialności
          za zasady ochrony prywatności na nich obowiązujące.
        </li>
      </ol>
    </>
  )
}

export default PrivacyPolicyContentTab3
