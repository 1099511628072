import React from "react"

import PageSubHeader from "components/PageSubHeader/PageSubHeader"

const PrivacyPolicyContentTab1 = () => {
  return (
    <>
      <PageSubHeader title="§ 1 Rodzaj przetwarzanych danych, cele oraz podstawa prawna" />
      <ol>
        <li>
          MEMTECH Sp. z o.o. Sp. k. zbiera informacje dotyczące osób fizycznych
          dokonujących czynności prawnej niezwiązanej bezpośrednio z ich
          działalnością, osób fizycznych prowadzących we własnym imieniu
          działalność gospodarczą lub zawodową oraz osób fizycznych
          reprezentujących osoby prawne lub jednostki organizacyjne niebędące
          osobami prawnymi, którym ustawa przyznaje zdolność prawną, zwanych
          dalej łącznie Klientami.
        </li>
        <li>Dane osobowe Klientów są zbierane w przypadku:</li>
        <ol>
          <li>
            rejestracji konta w Sklepie Internetowym, w celu utworzenia
            indywidualnego konta i zarządzania tym kontem. Podstawa prawna:
            niezbędność do wykonania umowy o świadczenie usługi Konta (art. 6
            ust. 1 lit. b RODO);
          </li>
          <li>
            składania zamówienia w Sklepie Internetowym, w celu wykonania umowy
            sprzedaży. Podstawa prawna: niezbędność do wykonania umowy sprzedaży
            (art. 6 ust. 1 lit. b RODO);
          </li>
          <li>
            subskrypcji biuletynu informacyjnego (Newsletter), w celu wykonania
            umowy, której przedmiotem jest usługa świadczona drogą
            elektroniczną. Podstawa prawna - zgoda osoby, której dane dotyczą na
            wykonanie umowy o świadczenie usługi Newsletter (art. 6 ust. 1 lit.
            a RODO);
          </li>
          <li>
            korzystania z usługi zamieść opinię, w celu wykonania umowy, której
            przedmiotem jest usługa świadczona drogą elektroniczną. Podstawa
            prawna - niezbędność do wykonania umowy o świadczenie usługi zamieść
            opinię (art. 6 ust. 1 lit. b RODO).
          </li>
          <li>
            korzystania z usługi zapytaj o produkt, w celu wykonania umowy,
            której przedmiotem jest usługa świadczona drogą elektroniczną.
            Podstawa prawna: niezbędność do wykonania umowy o świadczenie usługi
            zapytaj o produkt (art. 6 ust. 1 lit. b RODO).
          </li>
        </ol>
        <li>
          W przypadku rejestracji konta w Sklepie Internetowym, Klient podaje:
        </li>
        <ol>
          <li>adres e-mail;</li>
          <li>imię i nazwisko.</li>
        </ol>
        <li>
          Podczas rejestracji konta w Sklepie Internetowym Klient samodzielnie
          ustala indywidualne hasło dostępu do swojego konta. Klient może
          zmienić hasło, w późniejszym czasie, na zasadach opisanych w §5.
        </li>
        <li>
          W przypadku składania zamówienia w Sklepie Internetowym, Klient podaje
          następujące dane:
        </li>
        <ol>
          <li>adres e-mail;</li>
          <li>dane adresowe:</li>
          <li>kod pocztowy i miejscowość;</li>
          <li>ulica wraz z numerem domu/mieszkania.</li>
          <li>imię i nazwisko;</li>
          <li>numer telefonu.</li>
        </ol>
        <li>
          W przypadku Przedsiębiorców, powyższy zakres danych jest dodatkowo
          poszerzony o:
        </li>
        <ol>
          <li>firmę Przedsiębiorcy;</li>
          <li>numer NIP.</li>
        </ol>
        <li>
          W przypadku skorzystania z usługi Newsletter, Klient podaje jedynie
          swój adres e-mail.
        </li>
        <li>
          W przypadku skorzystania z usługi zamieść opinię, Klient podaje
          jedynie swój pseudonim, nick lub imię.
        </li>
        <li>
          W przypadku skorzystania z usługi zapytaj o produkt, Klient podaje:
        </li>
        <ol>
          <li>adres e-mail;</li>
          <li>imię;</li>
          <li>numer telefonu.</li>
        </ol>
        <li>
          Podczas korzystania ze Strony Internetowej Sklepu mogą być pobierane
          dodatkowe informacje, w szczególności: adres IP przypisany do
          komputera Klienta lub zewnętrzny adres IP dostawcy Internetu, nazwa
          domeny, rodzaj przeglądarki, czas dostępu, typ systemu operacyjnego.
        </li>
        <li>
          Od Klientów mogą być także gromadzone dane nawigacyjne, w tym
          informacje o linkach i odnośnikach, w które zdecydują się kliknąć lub
          innych czynnościach, podejmowanych w Sklepie Internetowym. Podstawa
          prawna - prawnie uzasadniony interes (art. 6 ust. 1 lit. f RODO),
          polegający na ułatwieniu korzystania z usług świadczonych drogą
          elektroniczną oraz na poprawie funkcjonalności tych usług.
        </li>
        <li>
          W celu ustalania, dochodzenia i egzekucji roszczeń mogą być
          przetwarzane niektóre dane osobowe podane przez Klienta w ramach
          korzystania z funkcjonalności w Sklepie Internetowym takie jak: imię,
          nazwisko, dane dotyczące korzystania z usług, jeżeli roszczenia
          wynikają ze sposobu, w jaki Klient korzysta z usług, inne dane
          niezbędne do udowodnienia istnienia roszczenia, w tym rozmiarów
          poniesionej szkody. Podstawa prawna - prawnie uzasadniony interes
          (art. 6 ust. 1 lit. f RODO), polegający na ustaleniu, dochodzeniu i
          egzekucji roszczeń oraz na obronie przed roszczeniami w postępowaniu
          przed sądami i innymi organami państwowymi.
        </li>
        <li>
          Przekazanie danych osobowych do MEMTECH Sp. z o.o. Sp. k. jest
          dobrowolne, w związku z zawieranymi umowami sprzedaży, czy też
          świadczenia usług za pośrednictwem Strony Internetowej Sklepu, z tym
          jednak zastrzeżeniem, że niepodanie określonych w formularzach danych
          w procesie Rejestracji uniemożliwia Rejestrację i założenie Konta
          Klienta, zaś w przypadku składania zamówienia bez Rejestracji Konta
          Klienta uniemożliwi złożenie i realizację zamówienia Klienta.
        </li>
      </ol>
    </>
  )
}

export default PrivacyPolicyContentTab1
