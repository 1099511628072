import React from "react"

import PageSubHeader from "components/PageSubHeader/PageSubHeader"

const PrivacyPolicyContentTab6 = () => {
  return (
    <>
      <PageSubHeader title="§ 6 Zmiany Polityki Prywatności" />
      <ol>
        <li>
          Polityka Prywatności może ulec zmianie, o czym MEMTECH Sp. z o.o. Sp.
          k. poinformuje Klientów z wyprzedzeniem 7 dni.
        </li>
        <li>
          Pytania związane z Polityką Prywatności prosimy kierować na adres:
          serwis@memtech.pl
        </li>
        <li>Data ostatniej modyfikacji: 26.11.2021 r.</li>
      </ol>
    </>
  )
}

export default PrivacyPolicyContentTab6
