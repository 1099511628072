import React from "react"

const PrivacyPolicyNav = ({ tab, setTab }) => {
  return (
    <div className="privacy-policy__nav">
      <button onClick={() => setTab(1)} className={tab === 1 ? "current" : ""}>
        Rodzaj przetwarzanych danych, cele oraz podstawa prawna
      </button>
      <button onClick={() => setTab(2)} className={tab === 2 ? "current" : ""}>
        Komu udostępniane lub powierzane są dane oraz jak długo są
        przechowywane?
      </button>
      <button onClick={() => setTab(3)} className={tab === 3 ? "current" : ""}>
        Mechanizm cookies, adres IP
      </button>
      <button onClick={() => setTab(4)} className={tab === 4 ? "current" : ""}>
        Prawa osób, których dane dotyczą
      </button>
      <button onClick={() => setTab(5)} className={tab === 5 ? "current" : ""}>
        Zarządzanie bezpieczeństwem - hasło
      </button>
      <button onClick={() => setTab(6)} className={tab === 6 ? "current" : ""}>
        Zmiany Polityki Prywatności
      </button>
    </div>
  )
}

export default PrivacyPolicyNav
