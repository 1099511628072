import React from "react"

import PageSubHeader from "components/PageSubHeader/PageSubHeader"

const PrivacyPolicyContentTab2 = () => {
  return (
    <>
      <PageSubHeader title="§ 2 Komu udostępniane lub powierzane są dane oraz jak długo są przechowywane?" />
      <ol>
        <li>
          Dane osobowe Klienta przekazywane są dostawcom usług, z których
          korzysta MEMTECH Sp. z o.o. Sp. k. przy prowadzeniu Sklepu
          Internetowego. Dostawcy usług, którym przekazywane są dane osobowe, w
          zależności od uzgodnień umownych i okoliczności, albo podlegają
          poleceniom MEMTECH Sp. z o.o. Sp. k. co do celów i sposobów
          przetwarzania tych danych (podmioty przetwarzające) albo samodzielnie
          określają cele i sposoby ich przetwarzania (administratorzy).
        </li>
        <ol>
          <li>
            Podmioty przetwarzające. MEMTECH Sp. z o.o. Sp. k. korzysta z
            dostawców, którzy przetwarzają dane osobowe wyłącznie na polecenie
            MEMTECH Sp. z o.o. Sp. k.. Należą do nich m.in. dostawcy świadczący
            usługę hostingu, usługi księgowe, dostarczający systemy do
            marketingu, systemy do analizy ruchu w Sklepie Internetowym, systemy
            do analizy skuteczności kampanii marketingowych;
          </li>
          <li>
            Administratorzy. MEMTECH Sp. z o.o. Sp. k. korzysta z dostawców,
            którzy nie działają wyłącznie na polecenie i sami ustalają cele i
            sposoby wykorzystania danych osobowych Klientów. Świadczą oni usługi
            płatności elektronicznych oraz bankowe.
          </li>
        </ol>
        <li>
          Lokalizacja. Dostawcy usług mają siedziby w Polsce i w innych krajach
          Europejskiego Obszaru Gospodarczego (EOG).
        </li>
        <li>Dane osobowe Klientów przechowywane są:</li>
        <ol>
          <li>
            W przypadku, gdy podstawą przetwarzania danych osobowych jest zgoda,
            wówczas dane osobowe Klienta przetwarzane są przez MEMTECH Sp. z
            o.o. Sp. k. tak długo, aż zgoda nie zostanie odwołana, a po
            odwołaniu zgody przez okres czasu odpowiadający okresowi
            przedawnienia roszczeń jakie może podnosić MEMTECH Sp. z o.o. Sp. k.
            i jakie mogą być podnoszone wobec niego. Jeżeli przepis szczególny
            nie stanowi inaczej, termin przedawnienia wynosi lat sześć, a dla
            roszczeń o świadczenia okresowe oraz roszczeń związanych z
            prowadzeniem działalności gospodarczej - trzy lata.
          </li>
          <li>
            W przypadku, gdy podstawą przetwarzania danych jest wykonanie umowy,
            wówczas dane osobowe Klienta przetwarzane są przez MEMTECH Sp. z
            o.o. Sp. k. tak długo, jak jest to niezbędne do wykonania umowy, a
            po tym czasie przez okres odpowiadający okresowi przedawnienia
            roszczeń. Jeżeli przepis szczególny nie stanowi inaczej, termin
            przedawnienia wynosi lat sześć, a dla roszczeń o świadczenia
            okresowe oraz roszczeń związanych z prowadzeniem działalności
            gospodarczej - trzy lata.
          </li>
        </ol>
        <li>
          W przypadku dokonania zakupu w Sklepie Internetowym, dane osobowe 4/11
          mogą być przekazywane, w zależności od wyboru Klienta, następującym
          podmiotom w celu dostarczenia zamówionych towarów:
        </li>
        <ol>
          <li>firmie kurierskiej;</li>
          <li>
            firmie InPost Paczkomaty Sp. z o.o. z siedzibą w Krakowie,
            świadczącej usługi dostawy i obsługi systemu skrytek pocztowych
            (Paczkomaty).
          </li>
        </ol>
        <li>
          W przypadku, gdy Klient wybierze płatność poprzez system
          przelewy24.pl, jego dane osobowe są przekazywane w zakresie niezbędnym
          dla realizacji płatności spółce PayPro S.A. z siedzibą w Poznaniu
          (60-327 Poznań, ul. Kanclerska 15), wpisanej do rejestru
          przedsiębiorców prowadzonego przez Sąd Rejonowy Poznań - Nowe Miasto i
          Wilda w Poznaniu, Wydział VIII Gospodarczy Krajowego Rejestru Sądowego
          pod numerem KRS 0000347935, NIP 7792369887, Regon 301345068.
        </li>
        <li>
          Dane nawigacyjne mogą być wykorzystywane w celu zapewnienia Klientom
          lepszej obsługi, analizy danych statystycznych i dostosowania Sklepu
          Internetowego do preferencji Klientów, a także administrowania Sklepem
          Internetowym.
        </li>
        <li>
          W przypadku, gdy Klient dokona subskrypcji biuletynu informacyjnego
          (Newsletter) na jego adres e-mail MEMTECH Sp. z o.o. Sp. k. będzie
          wysyłać wiadomości elektroniczne zawierające informacje handlowe o
          promocjach i nowych produktach dostępnych w Sklepie Internetowym.
        </li>
        <li>
          W przypadku skierowania żądania MEMTECH Sp. z o.o. Sp. k. udostępnia
          dane osobowe uprawnionym organom państwowym, w szczególności
          jednostkom organizacyjnym Prokuratury, Policji, Prezesowi Urzędu
          Ochrony Danych Osobowych, Prezesowi Urzędu Ochrony Konkurencji i
          Konsumentów lub Prezesowi Urzędu Komunikacji Elektronicznej.
        </li>
      </ol>
    </>
  )
}

export default PrivacyPolicyContentTab2
