import React from "react"

import PageSubHeader from "components/PageSubHeader/PageSubHeader"

const PrivacyPolicyContentTab4 = () => {
  return (
    <>
      <PageSubHeader title="§ 4 Prawa osób, których dane dotyczą" />
      <ol>
        <li>Prawo do cofnięcia zgody - podstawa prawna: art. 7 ust. 3 RODO.</li>
        <ol>
          <li>
            Klient ma prawo do cofnięcia każdej zgody, jakiej udzielił MEMTECH
            Sp. z o.o. Sp. k..
          </li>
          <li>Cofnięcie zgody ma skutek od momentu wycofania zgody.</li>
          <li>
            Cofnięcie zgody nie wpływa na przetwarzanie dokonywane przez MEMTECH
            Sp. z o.o. Sp. k. zgodnie z prawem przed jej cofnięciem.
          </li>
          <li>
            Cofnięcie zgody nie pociąga za sobą dla Klienta żadnych negatywnych
            konsekwencji, może jednak uniemożliwić dalsze korzystanie z usług
            lub funkcjonalności, które zgodnie z prawem MEMTECH Sp. z o.o. Sp.
            k. może świadczyć jedynie za zgodą.
          </li>
        </ol>
        <li>
          Prawo do sprzeciwu wobec przetwarzania danych - podstawa prawna: art.
          21 RODO.
        </li>
        <ol>
          <li>
            Klient ma prawo w dowolnym momencie wnieść sprzeciw - z przyczyn
            związanych z jego szczególną sytuacją - wobec przetwarzania jego
            danych osobowych, w tym profilowania, jeżeli MEMTECH Sp. z o.o. Sp.
            k. przetwarza jego dane w oparciu o prawnie uzasadniony interes, np.
            marketing produktów i usług MEMTECH Sp. z o.o. Sp. k., prowadzenie
            statystyki korzystania z poszczególnych funkcjonalności Sklepu
            Internetowego oraz ułatwienie korzystania ze Sklepu Internetowego, a
            także badanie satysfakcji.
          </li>
          <li>
            Rezygnacja w formie wiadomości e-mail z otrzymywania komunikatów
            marketingowych dotyczących produktów lub usług, będzie oznaczać
            sprzeciw Klienta na przetwarzanie jego danych osobowych, w tym 7/11
            profilowania w tych celach.
          </li>
          <li>
            Jeżeli sprzeciw Klienta okaże się zasadny i MEMTECH Sp. z o.o. Sp.
            k. nie będzie miał innej podstawy prawnej do przetwarzania danych
            osobowych, dane osobowe Klienta zostaną usunięte, wobec
            przetwarzania których, Klient wniósł sprzeciw.
          </li>
        </ol>
        <li>
          Prawo do usunięcia danych („prawo do bycia zapomnianym”) - podstawa
          prawna: art. 17 RODO.
        </li>
        <ol>
          <li>
            Prawo do usunięcia danych („prawo do bycia zapomnianym”) - podstawa
            prawna: art. 17 RODO.
          </li>
          <li>Klient ma prawo żądania usunięcia danych osobowych, jeżeli:</li>
          <ol>
            <li>
              dane osobowe nie są już niezbędne do celów, w których zostały
              zebrane lub w których były przetwarzane;
            </li>
            <li>
              wycofał określoną zgodę, w zakresie w jakim dane osobowe były
              przetwarzane w oparciu o jego zgodę;
            </li>
            <li>
              wniósł sprzeciw wobec wykorzystywania jego danych w celach
              marketingowych;
            </li>
            <li>dane osobowe są przetwarzane niezgodnie z prawem;</li>
            <li>
              dane osobowe muszą zostać usunięte w celu wywiązania się z
              obowiązku prawnego przewidzianego w prawie Unii lub prawie Państwa
              członkowskiego, któremu MEMTECH Sp. z o.o. Sp. k. podlega;
            </li>
            <li>
              dane osobowe zostały zebrane w związku z oferowaniem usług
              społeczeństwa informacyjnego.
            </li>
          </ol>
          <li>
            Pomimo żądania usunięcia danych osobowych, w związku z wniesieniem
            sprzeciwu lub wycofaniem zgody, MEMTECH Sp. z o.o. Sp. k. może
            zachować pewne dane osobowe w zakresie, w jakim przetwarzanie jest
            niezbędne do ustalenia, dochodzenia lub obrony roszczeń, jak również
            do wywiązania się z prawnego obowiązku wymagającego przetwarzania na
            mocy prawa Unii lub prawa państwa członkowskiego, któremu podlega
            MEMTECH Sp. z o.o. Sp. k.. Dotyczy to w szczególności danych
            osobowych obejmujących: imię, nazwisko, adres e-mail, które to dane
            zachowywane są dla celów rozpatrywania skarg oraz roszczeń
            związanych z korzystaniem z usług MEMTECH Sp. z o.o. Sp. k., czy też
            dodatkowo adresu zamieszkania/adresu korespondencyjnego, numeru
            zamówienia, które to dane zachowywane są dla celów rozpatrywania
            skarg oraz roszczeń związanych z zawartymi umowami sprzedaży lub
            świadczeniem usług.
          </li>
        </ol>
        <li>
          Prawo do ograniczenia przetwarzania danych - podstawa prawna: art. 18
          RODO.
          <ol>
            <li>
              Klient ma prawo do żądania ograniczenia przetwarzania jego danych
              osobowych. Zgłoszenie żądania, do czasu jego rozpatrzenia
              uniemożliwia korzystanie z określonych funkcjonalności lub usług,
              z których korzystanie będzie się wiązało z przetwarzaniem danych
              objętych żądaniem. MEMTECH Sp. z o.o. Sp. k. nie będzie też
              wysyłał żadnych komunikatów, w tym marketingowych.
            </li>
            <li>
              Klient ma prawo do żądania ograniczenia wykorzystania danych
              osobowych w następujących przypadkach:
            </li>
            <ol>
              <li>
                gdy kwestionuje prawidłowość swoich danych osobowych – wówczas
                MEMTECH Sp. z o.o. Sp. k. ogranicza ich wykorzystanie na czas
                potrzebny do sprawdzenia prawidłowości danych, nie dłużej jednak
                niż na 7 dni;
              </li>
              <li>
                gdy przetwarzanie danych jest niezgodne z prawem, a zamiast
                usunięcia danych Klient zażąda ograniczenia ich wykorzystania;
              </li>
              <li>
                gdy dane osobowe przestały być niezbędne do celów, w których
                zostały zebrane lub wykorzystywane ale są one potrzebne
                Klientowi w celu ustalenia, dochodzenia lub obrony roszczeń;
              </li>
              <li>
                gdy wniósł sprzeciw wobec wykorzystania jego danych – wówczas
                ograniczenie następuje na czas potrzebny do rozważenia, czy – ze
                względu na szczególną sytuację – ochrona interesów, praw i
                wolności Klienta przeważa nad interesami, które realizuje
                Administrator, przetwarzając dane osobowe Klienta.
              </li>
            </ol>
          </ol>
        </li>
        <li>Prawo dostępu do danych - podstawa prawna: art. 15 RODO.</li>
        <ol>
          <li>
            Klient ma prawo uzyskać od Administratora potwierdzenie, czy
            przetwarza dane osobowe, a jeżeli ma to miejsce, Klient ma prawo:
          </li>
          <ol>
            <li>uzyskać dostęp do swoich danych osobowych;</li>
            <li>
              uzyskać informacje o celach przetwarzania, kategoriach
              przetwarzanych danych osobowych, o odbiorcach lub kategoriach
              odbiorców tych danych, planowanym okresie przechowywania danych
              Klienta albo o kryteriach ustalania tego okresu (gdy określenie
              planowanego okresu przetwarzania danych nie jest możliwe), o
              prawach przysługujących Klientowi na mocy RODO oraz o prawie
              wniesienia skargi do organu nadzorczego, o źródle tych danych, o
              zautomatyzowanym podejmowaniu decyzji, w tym o profilowaniu oraz o
              zabezpieczeniach stosowanych w związku z przekazaniem tych danych
              poza Unię Europejską;
            </li>
            <li>uzyskać kopię swoich danych osobowych.</li>
          </ol>
        </ol>
        <li>Prawo do sprostowania danych - podstawa prawna: art. 16 RODO.</li>
        <ol>
          <li>
            Klient ma prawo do żądania od Administratora niezwłocznego
            sprostowania dotyczących jego danych osobowych, które są
            nieprawidłowe. Z uwzględnieniem celów przetwarzania, Klient, którego
            dane dotyczą ma prawo żądania uzupełnienia niekompletnych danych
            osobowych, w tym przez przedstawienie dodatkowego oświadczenia,
            kierując prośbę na adres poczty elektronicznej zgodnie z §6 Polityki
            Prywatności.
          </li>
        </ol>
        <li>Prawo do przenoszenia danych - podstawa prawna: art. 20 RODO.</li>
        <ol>
          <li>
            Klient ma prawo otrzymać swoje dane osobowe, które dostarczył
            Administratorowi, a następnie przesłać je do innego, wybranego przez
            siebie, administratora danych osobowych. Klient ma również prawo
            żądać, by dane osobowe zostały przesłane przez Administratora
            bezpośrednio takiemu administratorowi, o ile jest to technicznie
            możliwe. W takim przypadku Administrator prześle dane osobowe
            Klienta w postaci pliku w formacie csv, który jest formatem
            powszechnie używanym, nadającym się do odczytu maszynowego i
            pozwalającym na przesłanie otrzymanych danych do innego
            administratora danych osobowych.
          </li>
        </ol>
        <li>
          W sytuacji wystąpienia przez Klienta z uprawnieniem wynikającym z
          powyższych praw, MEMTECH Sp. z o.o. Sp. k. spełnia żądanie albo
          odmawia jego spełnienia niezwłocznie, nie później jednak niż w ciągu
          miesiąca po jego otrzymaniu. Jeżeli jednak - z uwagi na skomplikowany
          charakter żądania lub liczbę żądań – MEMTECH Sp. z o.o. Sp. k. nie
          będzie mógł spełnić żądania w ciągu miesiąca, spełni je w ciągu
          kolejnych dwóch miesięcy informując Klienta uprzednio w terminie
          miesiąca od otrzymania żądania - o zamierzonym przedłużeniu terminu
          oraz jego przyczynach.
        </li>
        <li>
          Klient może zgłaszać do Administratora skargi, zapytania i wnioski
          dotyczące przetwarzania jego danych osobowych oraz realizacji
          przysługujących mu uprawnień.
        </li>
        <li>
          Klient ma prawo żądać od MEMTECH Sp. z o.o. Sp. k. przekazania kopii
          standardowych klauzul umownych kierując zapytanie w sposób wskazany w
          §6 Polityki Prywatności.
        </li>
        <li>
          Klientowi przysługuje prawo do wniesienia skargi do Prezesa Urzędu
          Ochrony Danych Osobowych, w zakresie naruszenia jego praw do ochrony
          danych osobowych lub innych praw przyznanych na mocy RODO.
        </li>
      </ol>
    </>
  )
}

export default PrivacyPolicyContentTab4
