import React from "react"

import {
  PrivacyPolicyContentTab1,
  PrivacyPolicyContentTab2,
  PrivacyPolicyContentTab3,
  PrivacyPolicyContentTab4,
  PrivacyPolicyContentTab5,
  PrivacyPolicyContentTab6,
} from "./PrivacyPolicyContentTabs"

const PrivacyPolicyContent = ({ tab }) => {
  return (
    <div className="privacy-policy__content">
      {tab === 1 && <PrivacyPolicyContentTab1 />}
      {tab === 2 && <PrivacyPolicyContentTab2 />}
      {tab === 3 && <PrivacyPolicyContentTab3 />}
      {tab === 4 && <PrivacyPolicyContentTab4 />}
      {tab === 5 && <PrivacyPolicyContentTab5 />}
      {tab === 6 && <PrivacyPolicyContentTab6 />}
    </div>
  )
}

export default PrivacyPolicyContent
